@import './reset.css';

html,
body {
    background-color: var(--background);
    color: var(--text);
}

#app {
    min-height: 100vh;
    display: flex;
    justify-content: center;
}

